<template>
  <div id="app">
    <div class="grid-template">
          <!-- Grid Background -->
          <div class="grid-template--background" style="background-color:#212326;"></div>


          <!-- Grid Container -->
          <div class="grid-template--container">
          
              <!-- Template Header -->
              <div class="grid-template--header">
                <!-- If header logo -->
                <div class="header-logo">
                    <!-- Logo Centered-->
                    <div class="header-logo--wrapper centered">
                        <a href="#" class="width-4"><img src="assets/images/katarda-logo-white.svg" alt="Katarda"></a>
                    </div>
                </div>

                <!-- If have name -->
                <div class="header-name centered" style="color:#FFFF66;">
                    Car Rental & Tour Travel
                </div>
              </div>

              <slot/>

              <!-- Template Footer -->
              <div class="grid-template--footer d-block pt-5">
                  <div class="d-block text-center text-xs pt-5 pb-4" style="color:#ffffff;">
                      <span>Katarda Rental Car & Tour Travel</span>
                  </div>
              </div>
          </div>

          <!-- Template Block Footer-->
          <div class="grid-template--block-footer" style="background-color:#FFFF66;"></div>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'MainLayout'
    }
</script>
