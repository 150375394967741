import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '../views/Home.vue'
import Review from '../views/Review.vue'

import MainLayout from '../layout/MainLayout.vue'

Vue.use(VueRouter)
Vue.use(VueMeta);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout : MainLayout,
      transition: 'fade'
    }
  },
  {
    path: '/review',
    name: 'review',
    component: Review,
    meta: {
      layout : MainLayout,
      transition: 'fade'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }  
})

export default router