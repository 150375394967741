<template>
  <div>
      <!-- Template Banner -->
      <div class="grid-template--banner rounded">
          <div class="bannerSwiper">
              <div class="swiper-wrapper">

                  <!-- Banner Item-->
                  <div class="swiper-slide">
                      <div class="banner-item" style="background-image:url('assets/images/banner-katarda-1.png');">
                          <!-- Banner Item Caption -->
                          <div class="banner-item--caption"></div>
                      </div>
                  </div>
                  <!-- Banner Item-->
                  <div class="swiper-slide">
                      <div class="banner-item" style="background-image:url('assets/images/banner-katarda-2.png');">
                          <!-- Banner Item Caption -->
                          <div class="banner-item--caption"></div>
                      </div>
                  </div>

              </div>
          </div>

          <!-- If have button -->
          <div class="banner-button--wrapper">
              <div class="banner-button rounded-sm banner-button--prev"><i class="fa-solid fa-angle-left fa-sm"></i></div>
              <div class="banner-button rounded-sm banner-button--next"><i class="fa-solid fa-angle-right fa-sm"></i></div>
          </div>
      </div>

      <!-- Template Description -->
      <div class="grid-template--description centered" style="color:#ffffff;">
          <p>Kami menyediakan jasa penyewaan kendaraan besar dan kecil, serta jasa pengelolaan kegiatan tur & perjalanan Anda.</p>
      </div>

      <!-- Template Link -->
      <div class="grid-template--link">
          <a href="https://api.whatsapp.com/send/?phone=6285314108281&text=Halo+Katarda+Tour+Travel%2C+Saya+mau+sewa+mobil+Hiace.&app_absent=0" target="_blank" class="link-item link-have-image link-item--fill link-item-rounded" style="background-color:#FFFF66;border-color:#FFFF66;">

              <!-- Link Icon -->
              <!-- <div class="link-icon-main" style="color:#000000;"><i class="fa-brands fa-whatsapp fa-2x"></i></div> -->

              <!-- Link Image -->
              <div class="link-image" style="background-image:url('assets/images/hiace-1.png');"></div>

              <!-- Link Title -->
              <div class="link-title">Rental Toyota Hiace</div>

              <!-- Link Action-->
              <!-- <div class="link-action"></div> -->

          </a>

          <a href="https://api.whatsapp.com/send/?phone=6285314108281&text=Halo+Katarda+Tour+Travel%2C+Saya+mau+sewa+mobil.&app_absent=0" target="_blank" class="link-item link-have-image link-item--fill link-item-rounded" style="background-color:#FFFF66;border-color:#FFFF66;">

              <!-- Link Icon -->
              <!-- <div class="link-icon-main" style="color:#000000;"><i class="fa-brands fa-whatsapp fa-2x"></i></div> -->

              <!-- Link Image -->
              <div class="link-image" style="background-image:url('assets/images/innova-avanza.png');"></div>

              <!-- Link Title -->
              <div class="link-title">Rental Mobil (Innova & Avanza)</div>

              <!-- Link Action-->
              <!-- <div class="link-action"></div> -->

          </a>

          <a href="https://api.whatsapp.com/send/?phone=6285314108281&text=Halo+Katarda+Tour+Travel%2C+Saya+mau+konsultasi+tur+dan+perjalanan.&app_absent=0" class="link-item link-have-image link-item--fill link-item-rounded" target="_blank" style="background-color:#FFFF66;border-color:#FFFF66;">

              <!-- Link Icon -->
              <div class="link-icon-main" style="color:#000000;"><i class="fa-brands fa-whatsapp fa-2x"></i></div>

              <!-- Link Image -->
              <!-- <div class="link-image" style="background-image:url('assets/images/template/mcd/mcdapp.png');"></div> -->

              <!-- Link Title -->
              <div class="link-title">Konsultasi Tur & Perjalanan Anda</div>

              <!-- Link Action-->
              <!-- <div class="link-action"></div> -->

          </a>

          <a href="https://www.instagram.com/katardatour/" target="_blank" class="link-item link-have-image link-item--fill link-item-rounded" style="background-color:#FFFF66;border-color:#FFFF66;">

              <!-- Link Icon -->
              <div class="link-icon-main" style="color:#000000;"><i class="fa-brands fa-instagram fa-2x"></i></div>

              <!-- Link Image -->
              <!-- <div class="link-image" style="background-image:url('assets/images/template/mcd/mcdapp.png');"></div> -->

              <!-- Link Title -->
              <div class="link-title">Ikuti Kegiatan Kami di Instagram</div>

              <!-- Link Action-->
              <!-- <div class="link-action"></div> -->

          </a>

          <router-link :to="{name : 'review'}" class="link-item link-have-image link-item--fill link-item-rounded" style="background-color:#FFFF66;border-color:#FFFF66;">

              <!-- Link Icon -->
              <div class="link-icon-main" style="background-color:#000;color:#FFFF66;"><i class="fa-solid fa-comment fa-lg"></i></div>

              <!-- Link Image -->
              <!-- <div class="link-image" style="background-image:url('assets/images/template/mcd/mcdapp.png');"></div> -->

              <!-- Link Title -->
              <div class="link-title">Berikan Ulasan Perjalanan Anda Bersama Kami</div>

              <!-- Link Action-->
              <!-- <div class="link-action"></div> -->

          </router-link>
      </div>

      <!-- Template Gallery -->
      <div class="grid-template--gallery">
          <!-- Title -->
          <div class="grid-template--gallery-title centered" style="color:#ffffff">Dalam perjalanan Anda, kami selalu ada.</div>
          <!-- Row -->
          <div id="gridGallery" class="grid-template--gallery-row gallery-row-sm">

              <div class="gallery-item rounded" data-src="assets/images/katarda-img-1.png">
                  <div class="gallery-item--inner" style="background-image:url('assets/images/katarda-img-1.png')"></div>
              </div>
              <div class="gallery-item rounded" data-src="assets/images/katarda-img-2.png">
                  <div class="gallery-item--inner" style="background-image:url('assets/images/katarda-img-2.png')"></div>
              </div>
              <div class="gallery-item rounded" data-src="assets/images/katarda-img-3.png">
                  <div class="gallery-item--inner" style="background-image:url('assets/images/katarda-img-3.png')"></div>
              </div>
              <div class="gallery-item rounded" data-src="assets/images/katarda-img-4.png">
                  <div class="gallery-item--inner" style="background-image:url('assets/images/katarda-img-4.png')"></div>
              </div>
              <div class="gallery-item rounded" data-src="assets/images/katarda-img-5.png">
                  <div class="gallery-item--inner" style="background-image:url('assets/images/katarda-img-5.png')"></div>
              </div>
              <div class="gallery-item rounded" data-src="assets/images/katarda-img-6.png">
                  <div class="gallery-item--inner" style="background-image:url('assets/images/katarda-img-6.png')"></div>
              </div>
          </div>
      </div>

      <!-- Template Media -->
      <div class="grid-template--link grid-template--media">

          <a href="https://api.whatsapp.com/send/?phone=6285314108281&text=Halo+Katarda+Tour+Travel%2C+Saya+mau+konsultasi+tur+dan+perjalanan.&app_absent=0" target="_blank" class="link-item link-item--fill link-item-rounded" style="background-color:#000000;border-color:#000000;">
              <!-- Link Icon -->
              <div class="link-icon" style="color:#FFFF66;">
                  <i class="fa-brands fa-whatsapp fa-2x"></i>
              </div>
          </a>

          <a href="#" class="link-item link-item--fill link-item-rounded d-none" style="background-color:#000000;border-color:#000000;">
              <!-- Link Icon -->
              <div class="link-icon" style="color:#FFFF66;">
                  <i class="fa-brands fa-twitter fa-2x"></i>
              </div>
          </a>

          <a href="https://www.instagram.com/katardatour/" target="_blank" class="link-item link-item--fill link-item-rounded" style="background-color:#000000;border-color:#000000;">
              <!-- Link Icon -->
              <div class="link-icon" style="color:#FFFF66;">
                  <i class="fa-brands fa-instagram fa-2x"></i>
              </div>
          </a>

      </div>

  </div>
</template>

<script>
    export default {
        name: 'HomeView',
        metaInfo: {
            title: 'PT KATARDA INDO GLOBAL',
            titleTemplate: '%s | Katarda Car Rental & Tour Travel'
        },
        mounted() {
            this.getSwiper();
            this.getGrideGallery();
        },
        methods: {
            getSwiper: function () {
                let externalScript = document.createElement('script')
                externalScript.setAttribute('src', 'assets/js/custom-swiper.js')
                document.head.appendChild(externalScript)
            },
            getGrideGallery: function () {
                let externalScript = document.createElement('script')
                externalScript.setAttribute('src', 'assets/js/custom-gride-gallery.js')
                document.head.appendChild(externalScript)
            },
        }
    }
</script>