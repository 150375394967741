<template>
    <div>
      <!-- Form -->
      <div id="form-review" v-if="!succes">
          <!-- Template Description -->
          <div class="grid-template--description centered" style="color:#ffffff;">
              <h3 class="d-block mb-3">Tulis Ulasan</h3>
              <p>Berikan ulasan perjalanan Anda bersama Katarda Tour, sebuah masukan dari Anda sangat kami perlukan untuk dapat meningkatkan kualitas pelayanan kami.</p>
          </div>

          <!-- Form -->
          <div class="card border-0 shadow rounded-lg mb-4">
              <div class="card-body">
                  <div class="d-block p-3">
                     <form v-on:submit.prevent="onSubmit">
                          <div class="form-group">
                              <label class="small font-weight-bold" style="color:#000000;">Nama Lengkap</label>
                              <input type="text" class="form-control text-xs py-4 px-3" placeholder="Masukan nama lengkap Anda" v-model="form.fullname" :class="{ 'is-invalid': submitted && $v.form.fullname.$error }">
                              <div v-if="submitted && !$v.form.fullname.required" class="invalid-feedback">Nama lengkap wajib diisi!</div>    
                          </div>
                          <div class="form-group">
                              <label class="small font-weight-bold" style="color:#000000;">Nomor Telepon</label>
                              <input type="text" class="form-control text-xs py-4 px-3" placeholder="Masukan nomor telepon Anda" v-model="form.phone" :class="{ 'is-invalid': submitted && $v.form.phone.$error }">
                              <div v-if="submitted && !$v.form.phone.required" class="invalid-feedback">Nomor telepon wajib diisi!</div>    
                              <div v-if="submitted && !$v.form.phone.numeric" class="invalid-feedback">Nomor telepon harus berupa angka!</div>    
                              <div class="d-flex align-items-center pt-2">
                                  <div class="d-block mr-2">
                                      <i class="fa-solid fa-circle-info text-muted"></i>
                                  </div>
                                  <div class="form-text text-xs text-muted">Kami tidak akan pernah membagikan nomor telepon Anda kepada orang lain.</div>
                              </div>
                          </div>
                          <div class="form-group">
                              <label class="small font-weight-bold" style="color:#000000;">Tanggal Sewa</label>
                              <input type="date" class="form-control text-xs py-4 px-3" placeholder="Masukan tanggal sewa" v-model="form.rental_date" :class="{ 'is-invalid': submitted && $v.form.rental_date.$error }">
                              <div v-if="submitted && !$v.form.rental_date.required" class="invalid-feedback">Tanggal wajib diisi!</div>    
                          </div>
                          <div class="form-group">
                              <label class="small font-weight-bold" style="color:#000000;">Nama Supir</label>
                              <input type="text" class="form-control text-xs py-4 px-3" placeholder="Masukan nama supir" v-model="form.driver_name">
                              <div class="d-flex align-items-center pt-2">
                                  <div class="d-block mr-2">
                                      <i class="fa-solid fa-circle-info text-muted"></i>
                                  </div>
                                  <div class="form-text text-xs text-muted">Lewati jika tidak menggunakan supir dari kami.</div>
                              </div>
                          </div>
                          <div class="form-group">
                              <label class="small font-weight-bold" style="color:#000000;">Ulasan Anda</label>
                              <textarea class="form-control text-xs py-4 px-3" placeholder="Tulis ulasan Anda disini" v-model="form.content"></textarea>
                          </div>
                          <div class="d-block pt-4 text-center">
                                <button type="submit" :disabled="waiting" class="btn btn-primary btn-block btn-lg text-sm" style="background-color:#000000;color:#ffffff;">
                                    {{waiting ? 'Mengirim...':'Kirim Ulasan'}}
                                </button>
                                <div class="d-block text-xs text-center pt-3 pb-3">Terima kasih telah memberikan ulasan kepada kami.</div>
                                <router-link :to="{name : 'home'}" class="btn p-0 btn-link text-xs" style="height:auto!important;color:#000000;">Kembali ke halaman depan</router-link>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>

      <!-- Thank You -->
      <div class="card border-0 shadow rounded-lg mb-4" v-if="succes">
          <div class="card-body">
              <div class="d-block px-3 py-5">
                  <div class="d-block text-center" style="color:#000;">
                      <i class="fa-solid fa-envelope-circle-check fa-3x mb-3"></i>
                      <div class="d-block h3">Ulasan terkirim!</div>
                      <div class="d-block text-sm mb-5">
                          Terima kasih telah memberikan ulasan kepada kami. Sampai jumpa pada kesempatan lain waktu bersama Katarda Tour!
                      </div>
                      <router-link :to="{name : 'home'}" class="btn btn-primary text-xs" style="background:#000000;color:#fff;">Kembali ke halaman depan</router-link>
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
    import {
        required, numeric
    } from "vuelidate/lib/validators";

    export default {
        name: 'ReviewView',
        metaInfo: {
            title: 'PT KATARDA INDO GLOBAL',
            titleTemplate: '%s | Tulis Ulasan'
        },
        validations: {
            form: {
                fullname: {
                    required
                },
                phone: {
                    required,
                    numeric
                },
                rental_date: {
                    required
                }
            }
        },
        data() {
            return {
                form : {
                    id          : "",
                    fullname    : "",
                    phone       : "",
                    rental_date : "",
                    driver_name : "",
                    content     : ""
                },
                submitted       : false,
                waiting         : false,
                succes          : false
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi data terlebih dahulu',
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        closeOnConfirm: true,
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#000000',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('fullname', this.form.fullname);
                    formData.append('phone', this.form.phone);
                    formData.append('rental_date', this.form.rental_date);
                    formData.append('driver_name', this.form.driver_name);
                    formData.append('content', this.form.content);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'review/send', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.succes = true;
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    closeOnConfirm: true,
                                    confirmButtonText: 'OK',
                                    confirmButtonColor: '#000000',
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            closeOnConfirm: true,
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#000000',
                        });
                        this.waiting = false;
                        return;
                    });

                }                
            }
        }
    }
</script>
