var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_c('div',{staticClass:"grid-template--link"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('router-link',{staticClass:"link-item link-have-image link-item--fill link-item-rounded",staticStyle:{"background-color":"#FFFF66","border-color":"#FFFF66"},attrs:{"to":{name : 'review'}}},[_c('div',{staticClass:"link-icon-main",staticStyle:{"background-color":"#000","color":"#FFFF66"}},[_c('i',{staticClass:"fa-solid fa-comment fa-lg"})]),_c('div',{staticClass:"link-title"},[_vm._v("Berikan Ulasan Perjalanan Anda Bersama Kami")])])],1),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-template--banner rounded"},[_c('div',{staticClass:"bannerSwiper"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"banner-item",staticStyle:{"background-image":"url('assets/images/banner-katarda-1.png')"}},[_c('div',{staticClass:"banner-item--caption"})])]),_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"banner-item",staticStyle:{"background-image":"url('assets/images/banner-katarda-2.png')"}},[_c('div',{staticClass:"banner-item--caption"})])])])]),_c('div',{staticClass:"banner-button--wrapper"},[_c('div',{staticClass:"banner-button rounded-sm banner-button--prev"},[_c('i',{staticClass:"fa-solid fa-angle-left fa-sm"})]),_c('div',{staticClass:"banner-button rounded-sm banner-button--next"},[_c('i',{staticClass:"fa-solid fa-angle-right fa-sm"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-template--description centered",staticStyle:{"color":"#ffffff"}},[_c('p',[_vm._v("Kami menyediakan jasa penyewaan kendaraan besar dan kecil, serta jasa pengelolaan kegiatan tur & perjalanan Anda.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"link-item link-have-image link-item--fill link-item-rounded",staticStyle:{"background-color":"#FFFF66","border-color":"#FFFF66"},attrs:{"href":"https://api.whatsapp.com/send/?phone=6285314108281&text=Halo+Katarda+Tour+Travel%2C+Saya+mau+sewa+mobil+Hiace.&app_absent=0","target":"_blank"}},[_c('div',{staticClass:"link-image",staticStyle:{"background-image":"url('assets/images/hiace-1.png')"}}),_c('div',{staticClass:"link-title"},[_vm._v("Rental Toyota Hiace")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"link-item link-have-image link-item--fill link-item-rounded",staticStyle:{"background-color":"#FFFF66","border-color":"#FFFF66"},attrs:{"href":"https://api.whatsapp.com/send/?phone=6285314108281&text=Halo+Katarda+Tour+Travel%2C+Saya+mau+sewa+mobil.&app_absent=0","target":"_blank"}},[_c('div',{staticClass:"link-image",staticStyle:{"background-image":"url('assets/images/innova-avanza.png')"}}),_c('div',{staticClass:"link-title"},[_vm._v("Rental Mobil (Innova & Avanza)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"link-item link-have-image link-item--fill link-item-rounded",staticStyle:{"background-color":"#FFFF66","border-color":"#FFFF66"},attrs:{"href":"https://api.whatsapp.com/send/?phone=6285314108281&text=Halo+Katarda+Tour+Travel%2C+Saya+mau+konsultasi+tur+dan+perjalanan.&app_absent=0","target":"_blank"}},[_c('div',{staticClass:"link-icon-main",staticStyle:{"color":"#000000"}},[_c('i',{staticClass:"fa-brands fa-whatsapp fa-2x"})]),_c('div',{staticClass:"link-title"},[_vm._v("Konsultasi Tur & Perjalanan Anda")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"link-item link-have-image link-item--fill link-item-rounded",staticStyle:{"background-color":"#FFFF66","border-color":"#FFFF66"},attrs:{"href":"https://www.instagram.com/katardatour/","target":"_blank"}},[_c('div',{staticClass:"link-icon-main",staticStyle:{"color":"#000000"}},[_c('i',{staticClass:"fa-brands fa-instagram fa-2x"})]),_c('div',{staticClass:"link-title"},[_vm._v("Ikuti Kegiatan Kami di Instagram")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-template--gallery"},[_c('div',{staticClass:"grid-template--gallery-title centered",staticStyle:{"color":"#ffffff"}},[_vm._v("Dalam perjalanan Anda, kami selalu ada.")]),_c('div',{staticClass:"grid-template--gallery-row gallery-row-sm",attrs:{"id":"gridGallery"}},[_c('div',{staticClass:"gallery-item rounded",attrs:{"data-src":"assets/images/katarda-img-1.png"}},[_c('div',{staticClass:"gallery-item--inner",staticStyle:{"background-image":"url('assets/images/katarda-img-1.png')"}})]),_c('div',{staticClass:"gallery-item rounded",attrs:{"data-src":"assets/images/katarda-img-2.png"}},[_c('div',{staticClass:"gallery-item--inner",staticStyle:{"background-image":"url('assets/images/katarda-img-2.png')"}})]),_c('div',{staticClass:"gallery-item rounded",attrs:{"data-src":"assets/images/katarda-img-3.png"}},[_c('div',{staticClass:"gallery-item--inner",staticStyle:{"background-image":"url('assets/images/katarda-img-3.png')"}})]),_c('div',{staticClass:"gallery-item rounded",attrs:{"data-src":"assets/images/katarda-img-4.png"}},[_c('div',{staticClass:"gallery-item--inner",staticStyle:{"background-image":"url('assets/images/katarda-img-4.png')"}})]),_c('div',{staticClass:"gallery-item rounded",attrs:{"data-src":"assets/images/katarda-img-5.png"}},[_c('div',{staticClass:"gallery-item--inner",staticStyle:{"background-image":"url('assets/images/katarda-img-5.png')"}})]),_c('div',{staticClass:"gallery-item rounded",attrs:{"data-src":"assets/images/katarda-img-6.png"}},[_c('div',{staticClass:"gallery-item--inner",staticStyle:{"background-image":"url('assets/images/katarda-img-6.png')"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-template--link grid-template--media"},[_c('a',{staticClass:"link-item link-item--fill link-item-rounded",staticStyle:{"background-color":"#000000","border-color":"#000000"},attrs:{"href":"https://api.whatsapp.com/send/?phone=6285314108281&text=Halo+Katarda+Tour+Travel%2C+Saya+mau+konsultasi+tur+dan+perjalanan.&app_absent=0","target":"_blank"}},[_c('div',{staticClass:"link-icon",staticStyle:{"color":"#FFFF66"}},[_c('i',{staticClass:"fa-brands fa-whatsapp fa-2x"})])]),_c('a',{staticClass:"link-item link-item--fill link-item-rounded d-none",staticStyle:{"background-color":"#000000","border-color":"#000000"},attrs:{"href":"#"}},[_c('div',{staticClass:"link-icon",staticStyle:{"color":"#FFFF66"}},[_c('i',{staticClass:"fa-brands fa-twitter fa-2x"})])]),_c('a',{staticClass:"link-item link-item--fill link-item-rounded",staticStyle:{"background-color":"#000000","border-color":"#000000"},attrs:{"href":"https://www.instagram.com/katardatour/","target":"_blank"}},[_c('div',{staticClass:"link-icon",staticStyle:{"color":"#FFFF66"}},[_c('i',{staticClass:"fa-brands fa-instagram fa-2x"})])])])
}]

export { render, staticRenderFns }